'use client'

import { useRouter } from "next/navigation";
import Camera from "../Camera/Camera";
import DailyWord from "../DailyWord/DailyWord";
import MonthWeatherDisplay from "../MonthWeatherDisplay";
import TimeZone from "../Time/TimeZone";
import SunriseSunsetInfo from "../Weather/SunriseSunsetInfo";
import WidgetPromo from "../WidgetPromo";
import AdBanner from "../AdBanner";
import dynamic from "next/dynamic";
import Link from "next/link";

const Clock = dynamic(() => import('../Time/Clock'), { ssr: false });

const Accessories = () => {

    const router = useRouter();

    return (
        <article className="mx-3 xl:mx-44">
            <div className="flex flex-col xl:flex-row mt-4">
                <article className="flex-grow flex xl:flex-1 xl:mr-4 bg-white rounded">
                    <MonthWeatherDisplay />
                </article>

                <div className="xl:w-3/5 xl:flex xl:flex-col mt-4 xl:mt-0">
                    <article className="flex px-2 md:px-4 text-xs md:text-sm items-center bg-white rounded-t h-[44px]">
                        <p className="font-semibold text-[#083e56]">KAMERE GRANIČNI PRIJELAZI</p>
                        <Link className="inline-block sm:hidden ml-auto text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline" href="/granicni-prijelazi">Pogledaj sve</Link>
                        <Link className="hidden sm:inline-block ml-auto text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline" href="/granicni-prijelazi">Pogledaj sve kamere</Link>
                        <button className="ml-3 bg-[#ED1C24] hover:bg-[#d41118] transition duration-200 rounded" onClick={() => router.push('/granicni-prijelazi')}>
                            <p className="font-black text-white py-1 px-1.5">LIVE</p>
                        </button>
                    </article>
                    <article className="bg-white rounded mt-1 sm:p-4 flex items-center justify-center xl:flex-grow xl:w-full">
                        <div className="flex flex-row items-center justify-center">
                        <Camera city="Gunja" title="Ulaz iz HR iz BiH" snapshot="https://www.hak.hr/info/kamere/431.jpg" isBorder={true} cityId={'Gunja'} />
                        <Camera city="Gunja" title="Ulaz iz BiH iz HR" snapshot="https://www.hak.hr/info/kamere/432.jpg" isBorder={true} cityId={'Gunja'} />
                        </div>
                    </article>
                </div>
            </div>

            <div className="flex flex-col xl:flex-row mt-4">
                <article className="xl:flex-1 xl:mr-4">
                    <div className="flex px-2 md:px-4 text-xs md:text-sm items-center bg-white rounded-t h-[44px]">
                        <p className="font-semibold text-[#083e56]">KAMERE PO GRADOVIMA</p>
                        <Link className="inline-block sm:hidden ml-auto text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline" href="/kamere">Pogledaj sve</Link>
                        <Link className="hidden sm:inline-block ml-auto text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline" href="/kamere">Pogledaj sve kamere</Link>
                        <button className="ml-3 bg-[#ED1C24] hover:bg-[#d41118] transition duration-200 rounded" onClick={() => router.push('/kamere')}>
                            <p className="font-black text-white py-1 px-1.5">LIVE</p>
                        </button>
                    </div>
                    <div className="flex flex-col items-center justify-center bg-white rounded-b mt-1 xl:p-4 xl:min-h-[370px]">
                        <div className="flex flex-row items-center">
                            <Camera city="Zagreb" title="Ban Jelačić i Katedrala" snapshot="https://cdn.whatsupcams.com/snapshot/hr_zagreb7.jpg" cityId={691} cameraId={"hr_zagreb7"} />
                            <Camera city="Rijeka" title="Pogled na korzo" snapshot="https://cdn.whatsupcams.com/snapshot/hr_rijeka02.jpg" cityId={368} cameraId={"hr_rijeka02"} />
                        </div>
                        <div className="flex flex-row items-center mt-4">
                            <Camera city="Split" title="Matejurska" snapshot="https://cdn.whatsupcams.com/snapshot/hr_split.jpg" cityId={761} cameraId={"hr_split"} />
                            <Camera city="Pula" title="Arena-Amfiteater" snapshot="https://cdn.whatsupcams.com/snapshot/hr_pula03.jpg" cityId={417} cameraId={"hr_pula03"} />
                        </div>
                    </div>
                </article>
                <div className='inline-block lg:hidden mx-auto my-4 h-[250px] w-[300px]' style={{ marginLeft: "50%", transform: "translateX(-50%)", maxWidth: "100vw" }}>
                    <AdBanner data-ad-slot="9436072472" data-ad-format="auto" data-full-width-responsive="true" customKey="hero-banner-24" />
                </div>
                <article className="xl:w-3/5 bg-white rounded p-4 xl:flex mt-4 xl:mt-0">
                    <WidgetPromo />
                </article>
            </div>

            <div className='hidden lg:inline-block mx-auto my-4 h-[170px] w-[970px]' style={{ marginLeft: "50%", transform: "translateX(-50%)", maxWidth: "100vw" }}>
                <AdBanner data-ad-slot="9436072472" data-ad-format="auto" data-full-width-responsive="true" customKey="hero-banner-25" />
            </div>

            <div className="flex flex-col xl:flex-row mt-4 mb-4">
                <div className="flex-grow xl:flex-1 xl:mr-4">
                    <DailyWord />
                    <article className="bg-white rounded mt-4 p-4 flex">
                        <SunriseSunsetInfo />
                    </article>
                </div>
                <div className="flex xl:w-3/5 bg-white rounded p-4 items-center mt-4 xl:mt-0">
                    <article className="hidden md:inline-block xl:flex-1 xl:pr-4 xl:border-r">
                        <Clock />
                    </article>
                    <article className="flex md:w-2/3 items-center justify-center">
                        <div className="flex flex-auto flex-col mx-4">
                            <p className="pb-4 font-semibold text-sm">Vremenska zona u Zagrebu (GMT+2)</p>
                            <img className="flex-auto" src="timezone-chart.svg" alt="Widget Example"></img>
                            <div className="pt-4">
                                <TimeZone />
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </article>
    );
};

export default Accessories;