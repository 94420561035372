'use client'

import { useEffect, useState, useMemo } from 'react';
import { Icon } from '@iconify/react';

const SunriseSunsetInfo = () => {
    const [sunriseTime, setSunriseTime] = useState('');
    const [sunsetTime, setSunsetTime] = useState('');
    const [currentDayLength, setCurrentDayLength] = useState('');
    const [timeDifference, setTimeDifference] = useState('');

    useEffect(() => {
        const getSunriseSunset = async () => {
            try {
                const response = await fetch('https://api.sunrisesunset.io/json?lat=45.1&lng=15.2');
                const data = await response.json();

                const formatTime = (timeString) => {
                    const [time, period] = timeString.split(' ');
                    const [hours, minutes] = time.split(':');
                    let formattedHours = (hours % 12) || 12;
                    if(period === 'PM'){
                        formattedHours += 12;
                    }
                    return `${formattedHours}:${minutes}`;
                };

                const formatDayLength = (dayLengthString) => {
                    const [hours, minutes, seconds] = dayLengthString.split(':');
                    return `${hours}h ${minutes}m ${seconds}s`;
                };

                const sunrise = formatTime(data.results.sunrise);
                const sunset = formatTime(data.results.sunset);
                const dayLength = formatDayLength(data.results.day_length);

                setSunriseTime(sunrise);
                setSunsetTime(sunset);
                setCurrentDayLength(dayLength);

                const responseYesterday = await fetch('https://api.sunrisesunset.io/json?lat=45.1&lng=15.2&date=yesterday');
                const dataYesterday = await responseYesterday.json();
                const yesterdayDayLength = dataYesterday.results.day_length;

                setTimeDifference(calculateTimeDifference(data.results.day_length, yesterdayDayLength));
            } catch (error) {
                console.error('Error fetching sunrise and sunset times:', error);
            }
        };

        getSunriseSunset();
    }, []);

    const calculateTimeDifference = (currentDayLength, yesterdayDayLength) => {
        const [currentHours, currentMinutes, currentSeconds] = currentDayLength.split(':').map(Number);
        const [yesterdayHours, yesterdayMinutes, yesterdaySeconds] = yesterdayDayLength.split(':').map(Number);

        const currentTotalSeconds = (currentHours * 3600) + (currentMinutes * 60) + currentSeconds;
        const yesterdayTotalSeconds = (yesterdayHours * 3600) + (yesterdayMinutes * 60) + yesterdaySeconds;

        const timeDifferenceSeconds = currentTotalSeconds - yesterdayTotalSeconds;
        const timeDifferenceMinutes = Math.round(timeDifferenceSeconds / 60);

        return timeDifferenceMinutes;
    };

    const formattedTimeDifference = useMemo(() => {
        if (timeDifference !== '') {
            return timeDifference >= 0 ? `${timeDifference} minute duže nego jučer` : `${-timeDifference} minute kraće nego jučer`;
        }
        return '';
    }, [timeDifference]);

    return (
        <div className="flex text-sm w-full">
            <div className="flex flex-col items-center">
                <img className="flex" src="sunrise-sunset.png" alt="Sunrise Sunset"></img>
                <div className="flex items-center">
                    <Icon icon="lucide:sunrise" className="mr-2" />
                    <p className="mr-4">{sunriseTime}</p>
                    <Icon icon="lucide:sunset" className="mr-2 ml-auto" />
                    <p>{sunsetTime}</p>
                </div>
            </div>
            <div className="w-2/3 mx-auto pl-4 flex items-center">
                <div>
                    <p>Vrijeme dnevnog svjetla: {currentDayLength} </p>
                    <p>{formattedTimeDifference}</p>
                </div>
            </div>
        </div>
    );
};

export default SunriseSunsetInfo;
