'use client'

import { DateTime } from 'luxon';

const findLastSunday = (year, month) => {
    let date = DateTime.local(year, month, 1).endOf('month');
    while (date.weekday !== 7) {
        date = date.minus({ days: 1 });
    }
    return date;
};

const getDSTInfo = () => {
    const now = DateTime.now().setZone('Europe/Zagreb');
    const year = now.year;

    const startDST = findLastSunday(year, 3).set({ hour: 2 });
    const endDST = findLastSunday(year, 10).set({ hour: 3 });

    let nextTransition;
    if (now < startDST) {
        nextTransition = startDST;
    } else if (now < endDST) {
        nextTransition = endDST;
    } else {
        nextTransition = findLastSunday(year + 1, 3).set({ hour: 2 });
    }

    const isDSTForward = nextTransition.equals(startDST);

    const formattedDate = nextTransition.toFormat('dd.MM.yyyy.', { locale: 'hr' });
    const formattedTime = nextTransition.toFormat('HH:mm');

    return {
        zoneName: now.zoneName,
        date: formattedDate,
        time: formattedTime,
        isForward: isDSTForward,
    };
};

const TimeZone = () => {
    const dstInfo = getDSTInfo();

    return (
        <div className="flex">
            <p className="pr-2 font-semibold text-sm">Sljedeća promjena:</p>
            {dstInfo ? (
                <div>
                    <p className="text-sm">
                        {dstInfo.isForward ? 'CEST - Srednjoeuropsko ljetno vrijeme' : 'CET - Srednjoeuropsko vrijeme'}
                    </p>
                    <p className="text-sm">
                        {dstInfo.date} {dstInfo.time} ({dstInfo.isForward ? '1 sat unaprijed' : '1 sat unatrag'})
                    </p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default TimeZone;
