'use client'

import { useEffect, useState } from "react";

const DailyWord = () => {
    const [wordData, setWordData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`/api/pojmovnik-random`);
                const data = await response.json();
                setWordData(data.result);
            } catch (error) {
                console.error("Error fetching pojmovnik data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <a href={`/pojmovnik?slug=${wordData?.slug}`}>
            <div className="flex items-center bg-white rounded-t h-[44px]">
                <p className="px-4 text-sm font-semibold text-[#083e56]">POJMOVNIK - RIJEČ DANA</p>
            </div>
            <div className="bg-white rounded mt-1 p-4 md:min-h-[108px]">
                <div className="flex flex-row items-center text-sm">
                    <p><b>{wordData?.title}: </b>{wordData?.content}</p>
                </div>
            </div>
        </a>
    );
};

export default DailyWord;